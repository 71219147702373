<template>
  <div class="content-wrapper">
    <!-- REPORT -->
    <report-view ref="reportViewComponent" :isPopup="true"/>
    <!-- 필터영역 -->
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">사용자</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist v-model="searchFilter.userQueryDiv"
                                  :dataSource="commonCodeOptions.userQueryDiv"
                                  :fields="commonCodeOptions.fields"
                                  :allowFiltering="false"
                                  cssClass="lookup-condition-dropdown" />
              </li>
              <li class="item">
                <input-text v-model="searchFilter.userQueryName" @keydown.enter="onClickSearch"/>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">그룹권한</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist v-model="searchFilter.groupAuth"
                                  :dataSource="commonCodeOptions.groupAuth"
                                  :fields="commonCodeOptions.fields"
                                  :allowFiltering="false"
                                  :width="200"
                                  cssClass="lookup-condition-dropdown" />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET" :is-shortcut-button="true" :ignore="isPopupOpened" @click.native="onClickSearch">조회</erp-button>
        </div>
      </div>
      <div class="lookup-right"> <!-- 사용시 좌측이 없을 경우 생략-->
        <ul class="lookup-button"></ul>
      </div>
    </div>
    <!-- 본문 -->
    <div class="content-body">
      <article class="body-article">
        <!-- ######## 목록 리스트 ########-->
        <section class="article-section">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">{{gridField.title}}</div>
              <div class="header-caption">[ {{ gridField.count }} 건 ]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button button-div="SAVE" :ignore="isPopupOpened" @click.native="onSmsSendButtonClicked">SMS전송</erp-button>
                </li>
                <li class="print">
                  <erp-button button-div="FILE" :ignore="isPopupOpened" @click.native="onClickExcel">Excel</erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="grid"
                  v-bind ="initGrid"
                  @headerCellInfo = "onHeaderCellInfo"
                  @queryCellInfo="onQueryCellInfo"
                  @actionComplete="gridComplete"
                  @recordClick="onGridClick"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <!-- 팝업 -->
    <sms-send-popup
        v-if="isSmsSendPopupOpen"
        ref="smsSendPopup"
        @popupClosed="smsSendPopupClosed"
    />
  </div>
</template>

<script>

import commonMixin from "@/views/layout/mixin/commonMixin";
import ReportView from "@/components/common/report/ReportView.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {Edit, ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputText from "@/components/common/text/InputText.vue";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

import smsSendPopup from "@/views/common/SmsSendPopup.vue";
import SmsSendForUser from "@/api/v2/sms/SmsSendForUser";
import {getFormattedPhoneNumber} from "@/utils/string";

export default {
  name: "SmsSendForUser",
  mixins: [commonMixin, MessagePopupDialogMixin],
  components: {
    smsSendPopup,
    ReportView,
    ErpButton,
    EjsGridWrapper,
    InputText,
  },

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      //팝업여부
      isSmsSendPopupOpen : false,
      //조회 필드
      searchFilter: {
        userQueryDiv:"name",
        userQueryName:"",
        groupAuth:"",
      },
      //드롭다운 코드
      commonCodeOptions: {
        userQueryDiv: [],
        groupAuth: [],
        fields: { text: "comName", value: "comCode" },
      },
      //그리드 필드
      gridField: {
        title: "사용자 목록",
        count : 0,
        dataSource: [],
      },
    };
  },
  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    // 팝업시 메인화면 버튼의 단축키 조정 , 팝업이 없으면 false 리턴
    isPopupOpened() {
      return this.isSmsSendPopupOpen;
    },
    initGrid() {
      return {
        columns: [
          { field: "isExcept",    textAlign: 'center',  allowEditing: true,  width: 50,  visible: true,   type: "boolean",  headerText: "제외",  displayAsCheckBox: true, editType: "booleanedit"},
          { field: "userId",      textAlign: 'center',  allowEditing: false, width: 100,  visible: false,  type: "string",   headerText: "사용자ID",  },
          { field: "userName",    textAlign: 'center',  allowEditing: false, width: 100,  visible: true,   type: "string",   headerText: "사용자명",  },
          { field: "email",       textAlign: 'left',    allowEditing: false, width: 150,  visible: true,   type: "string",   headerText: "이메일",  },
          { field: "hpNo",        textAlign: 'center',  allowEditing: false, width: 150,  visible: true,   type: "string",   headerText: "핸드폰",  valueAccessor: this.telNumberValueAccess,},
          { field: "remarks",     textAlign: 'left',    allowEditing: false, width: 200,  visible: true,   type: "string",   headerText: "업무/부서",  },
          { field: "groupName",   textAlign: 'left',    allowEditing: false, width: 250,  visible: true,   type: "string",   headerText: "그룹권한",  },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: { pageSize: 50 },
        resizeSettings: {mode:"Normal"},
        //그리드 정합성 체크
        validationRules: {
          //userNmae: {required: true,},
        },
      };
    },
  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
  },
  async mounted() {
    //기초데이터 세팅
    await this.initData();
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //******************************* 버튼 이벤트 *******************************
    //기초데이터 세팅
    async initData() {
      //유저검색 타입
      this.commonCodeOptions.userQueryDiv = [{comName: "사용자명", comCode: "name"}, {comName: "사용자 ID", comCode: "id"}, {comName: "업무/부서", comCode: "remarks"},];
      //유저그룹
      this.commonCodeOptions.groupAuth  = (await GolfErpAPI.fetchAuthorityGroups()).map(({ id, name }) => ({comName:name, comCode:id}));
      this.commonCodeOptions.groupAuth.unshift({ comName: "", comCode: "" });
    },
    //조회
    async onClickSearch() {
      this.gridField.dataSource = await SmsSendForUser.getUserList(this.searchFilter);
    },
    //엑셀 출력
    onClickExcel() {
      this.$refs.grid.excelExport({fileName: this.gridField.title+".xlsx"});
    },
    //SMS 전송 팝업호출
    onSmsSendButtonClicked() {
      //대상자 체크(체크는 수정행에 있으므로 원본데이터에는 없음. 현재화면에 보여지는 데이터 기준으로 가져오자)
      //const sendList = this.gridField.dataSource.filter(item => !item.isExcept && item.hpNo !=="");
      const sendList = this.$refs.grid.getBatchCurrentViewRecords().filter(item => !item.isExcept && item.hpNo);
      if (sendList.length === 0) {
        this.errorToast("SMS 전송할 대상자가 없습니다.(핸드폰 미등록자 제외됨)");
        return;
      }
      const smsSendData = {
        smsSendType: "NOTE",
        rcverName: null,
        rcverInfo: null,
        sendKey: "0",
        pageType : "SmsSendForUser",
        sendList : sendList
      };
      this.isSmsSendPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.smsSendPopup.showSmsSendPopup(smsSendData);
      });
    },
    //SMS 팝업닫기
    smsSendPopupClosed() {
      this.isSmsSendPopupOpen = false;
    },
    telNumberValueAccess(field, data) {
      let tel = data[field];
      if (tel != null) {
        tel = getFormattedPhoneNumber(tel);
      }
      return tel;
    },
    //******************************* 그리드 이벤트 *******************************
    onHeaderCellInfo(args){
      const {cell: {column:{field}}, node} = args;
      if(this.$refs.grid.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    onQueryCellInfo(args){
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
      if (['userName'].includes(field)) {
        cell.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    //그리드 셀 클릭
    onGridClick(args){
      const {column:{field}, rowData} = args;
      if(field === "userName"){

        if(!rowData.hpNo){
          this.errorToast("핸드폰 번호가 등록되지 않은 사용자입니다.");
          return;
        }
        const smsSendData = {
          smsSendType: "NOTE",
          rcverName: rowData.userName,
          rcverInfo: rowData.hpNo,
          sendKey: "0",
          pageType : "SmsSendForUser",
        };
        this.isSmsSendPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.smsSendPopup.showSmsSendPopup(smsSendData);
        });
      }
    },
  }
};
</script>

<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
body .appContent .body-article .article-left{width:100%;}
body .body-footer .section-header {display: block;position: relative;margin: 0px 0px;}
</style>
