import GolfErpAPI from "@/api/v2/GolfErpAPI";

export const ROOT_PATH = '/sms-send-for-user';

class SmsSendForUser {

    /** USER 리스트 */
    async getUserList(params) {
        const {data: data} = await GolfErpAPI.http.get(`${ROOT_PATH}/user`, {params});
        return data;
    }
}

export default new SmsSendForUser();
